<template>
	<div>
		<layout>
			<br />
			<el-row :gutter="20">

				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">活动名称:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="m_huodong_name" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col>
					<el-col :span="16">
					<el-button class="yu_right_1" @click="drawer_1 = true" plain>
						<i class="el-icon-plus"></i>添加活动
					</el-button>
				</el-col>
			</el-row>
			<br />


			<br />
			<el-table :data="m_huodong_list" style="width: 100%">
				<el-table-column prop="hudo_id" label="id">
				</el-table-column>
				<el-table-column prop="hudo_title" label="活动名称" width="120">
				</el-table-column>
				
				<el-table-column prop="hudo_name" label="发布人昵称"  >
				</el-table-column>
				<!-- <el-table-column prop="hudo_menpiao" label="门票价格"  >
				</el-table-column> -->
				<el-table-column label="操作" width="220" align="right">
					<template slot-scope="scope">
						<el-button size="mini" type="default" @click="f_huodong_set(scope.row.hudo_id)">
							修改信息 
						</el-button> 
					</template>
				</el-table-column>
			</el-table>

			<br />
			<br />

			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>
		<el-drawer title="添加活动" :visible.sync="drawer_1" size="600px">
			<huodong_add :parent="me"></huodong_add>
		</el-drawer>

		<el-drawer title="修改活动" :visible.sync="drawer_2" size="600px">
			<huodong_set :key="m_hudo_id" :m_hudo_id="m_hudo_id" :parent="me"></huodong_set>
		</el-drawer>
		<!-- <el-drawer title="预定信息" :visible.sync="drawer_2" size="600px">
			<yuding_info :key="m_chdi_id" :m_chdi_id="m_chdi_id" :parent="me"></yuding_info>
		</el-drawer>
		<el-drawer title="场地费用设置" :visible.sync="drawer_3" size="600px">
			<shoufei_set :key="m_chdi_id" :m_chdi_id="m_chdi_id" :parent="me"></shoufei_set>
		</el-drawer> -->
		 
	</div>
</template>

<script>
	import layout from 'views/layout.vue'

	import huodong_add from './components/huodong_add/huodong_add'
	import huodong_set from './components/huodong_set/huodong_set'
	 
	import huodong_get from "./expand/huodong_get.js"


	export default {
		components: {
			layout,
			huodong_add, 
			huodong_set, 
		},
		data() {
			return {
				me: this,
				m_huodong_name: "",
				m_huodong_list: [],
				drawer_1: false,
				drawer_2: false,
				drawer_3: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10,
				m_hudo_id: 0, 
				haha: false
			}
		},
		created() {
			const that = this;

			that.huodong_get = new huodong_get(that);
			that.huodong_get.m_main();

		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.huodong_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;
				that.m_huodong_name = val;
				that.huodong_get.m_main();
			}, 
			f_huodong_set(huodong_id) {
				//点击了预定信息
				const that = this; 
				  
				 
				that.m_hudo_id = huodong_id;
				that.drawer_2 = true;
			},
			f_go_shangpin(shan_id) {
				const that = this;
				that.$router.push({
					path: '/shangpin',
					query: {
						"changdi_id": shan_id
					}
				});
			},
			f_go_manghe(shan_id) {
				const that = this;
				that.$router.push({
					path: '/manghe',
					query: {
						"changdi_id": shan_id
					}
				});
			},
			f_go_yiyuanchou(shan_id) {
				const that = this;
				that.$router.push({
					path: '/yiyuanchou',
					query: {
						"changdi_id": shan_id
					}
				});
			}
		}
	}
</script>